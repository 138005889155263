


const databits = [
  {
    title: 'Productos certificados',
    content:
      'Nuestros productos químicos se fabrican bajo estrictos estándares de calidad e inocuidad, lo que garantiza su buen desempeño y consistencia lote tras lote. Todos los productos cuentan con notificación/registro sanitario, y cumplen con especificaciones nacionales e internacionales.',
    
      featureName: 'productos químicos certificados ',
    
    featureImage: '../../images/IconeCertificados.svg'
  },
  {
    title: 'Amigables con el medio ambiente',
    content:
      'Nuestros productos son amigables con el medio ambiente. Desde la etapa de desarrollo y formulación, escogemos cuidadosamente materias primas con alto grado de biodegradabilidad y empaques 100% reciclables. Nuestras fórmulas están libres de moléculas bio-acumulativas o nanotecnología cuya interacción con la naturaleza es poco conocida y no se puede controlar.',
    
      featureName: 'Productos amigables con el medio ambiente.',
    
    featureImage: '../../images/IconeMedioAmbiente.svg'
  },
  
  {
    title: 'Rendimiento',
    content:
      'Nuestros productos químicos son altamente concentrados, por lo cual se pueden diluir, lo que genera un gran rendimiento y un bajo costo en uso. Usados con dosificadores, se garantiza y controla el rendimiento de nuestras soluciones químicas. Por último, entrenamos y capacitamos al personal de limpieza en el manejo adecuado de soluciones químicas para evitar el desperdicio.',
     
      featureName: 'Productos químicos rendidores, concentrados',
    
    featureImage: '../../images/IconeRendimiento.svg'
  },
  {
    title: 'Seguridad',
    content:
      'La seguridad de los usuarios es un objetivo principal al momento de formular nuestros productos químicos. Nuestras formulas desarrolladas con tecnología alemana, contienen ingredientes considerados seguros de acuerdo con el estado del arte de la química. Nuestras fórmulas están libres de productos tóxicos para la salud humana, como son: formol o formaldehido, parabenos, triclosán, entre otros.',
     
      featureName: 'Productos químicos rendidores, concentrados',
    
    featureImage: '../../images/IconeSeguridad.svg'
  }
  

];
export default databits;
